export const BIOS = [
	{
		year: 1999,
		text: 'Born in Skopje, Macedonia'
	},

	{
		year: 2018,
		text: 'Finished High School and started studies at the Faculty of Computer Science and Engineering'
	},

	{
		year: 2021,
		text: 'Started at Endava through its Graduate program'
	}
];
